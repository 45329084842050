import Keycloak from 'keycloak-js';

const keycloakConfig = {
  url: 'https://keycloak.evaplus.net/auth',
  realm: 'Evaplus',
  clientId: 'evaplus-web',
  onLoad: 'check-sso',
};
const keycloak = new Keycloak(keycloakConfig);

export default keycloak;
